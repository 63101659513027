import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'app-annonce-savoir-plus',
	standalone: true,
	templateUrl: './annonce-savoir-plus.component.html',
	styleUrl: './annonce-savoir-plus.component.css',
	imports: [TranslateModule],
})
export class AnnonceSavoirPlusComponent {}
