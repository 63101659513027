<div
	class="bg-white p-5 rounded-lg shadow-md flex flex-col sm:flex-row items-center space-y-4 md:space-y-0 md:space-x-4 w-auto"
>
	<div class="flex flex-col">
		<p class="text-black font-bold text-lg mb-2">
			Une banque en ligne améliorée, pour une meilleure expérience
		</p>
		<p class="text-gray-600 text-sm">
			Créez votre compte en un seul clic, et complétez votre profil en toute
			simplicité.
		</p>
		<div class="flex justify-end space-x-1 mt-4">
			<span class="inline-block w-2 h-2 bg-red-500 rounded-full"></span>
			<span class="inline-block w-2 h-2 bg-gray-400 rounded-full"></span>
			<span class="inline-block w-2 h-2 bg-gray-400 rounded-full"></span>
		</div>
	</div>

	<div class="flex-none w-full md:w-auto">
		<img
			src="assets/images/Screenshot-2024-06-30-at-00.03.02 1.svg"
			alt="Annonce 1"
			class="w-full h-40 rounded-lg"
		/>
	</div>
</div>
