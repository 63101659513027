<div class="relative overflow-hidden">
    <div class="flex transition-transform duration-300 ease-in-out" [style.transform]="'translateX(-' + currentIndex * 100 + '%)'">
        <div *ngFor="let slide of slides; let i = index" class="flex-shrink-0 w-full">
            <div *ngIf="slide.type === 'image'" class="relative w-full h-full">
                <img [src]="slide.imageUrl" [alt]="slide.caption || ''" class="w-full h-full object-cover">
            </div>
        </div>
    </div>

    <ng-container *ngIf="slides.length > 1">
        <button *ngIf="showArrows" (click)="prevSlide()" class="absolute top-1/2 left-2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 focus:outline-none">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
        </button>

        <button *ngIf="showArrows" (click)="nextSlide()" class="absolute top-1/2 right-2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 focus:outline-none">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
        </button>

        <div *ngIf="showDots" class="absolute bottom-10 left-0 right-0 flex justify-center">
            <button *ngFor="let slide of slides; let i = index"
                    (click)="goToSlide(i)"
                    class="mx-1 w-3 h-3 rounded-full focus:outline-none"
                    [class.bg-white]="i === currentIndex"
                    [class.bg-primary]="i !== currentIndex">
            </button>
        </div>
    </ng-container>
</div>
